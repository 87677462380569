import React from 'react';
import dynamic from 'next/dynamic';
import {WPFetch} from 'aac-components/utils/fetch';
import {shouldShowError, isSpanishPage} from '../lib/utils';
import CustomError from './_error';
import {getPageByPath} from '../lib/post';

const FullWidthPageTemplate = dynamic(() =>
    import('../components/PageTemplates/fullWidthPageTemplate'),
);
const PpcTempate = dynamic(() => import('../components/PageTemplates/ppc'));

const DefaultPageTemplate = dynamic(() => import('../components/PageTemplates/Default'));

const PageBuilderTemplate = dynamic(() =>
    import('../components/PageTemplates/PageBuilderTemplate'),
);
const ComplianceTemplate = dynamic(() =>
    import('../components/PageTemplates/ComplianceTemplate'),
);

const PageTemplates = (props) => {
    const {data = []} = props;
    const {template = ''} = data;

    // error page
    const hasError = shouldShowError(props);

    if (hasError || data?.type !== 'page') {
        return <CustomError statusCode={hasError} />;
    }

    // templates
    const renderTemplate = () => {
        switch (template) {
            case 'full-width.php':
                return <FullWidthPageTemplate data={data} />;
            case 'ppc.php':
                return <PpcTempate data={data} />;
            case 'page-builder.php':
                return <PageBuilderTemplate data={data} />;
            case 'compliance.php':
                return <ComplianceTemplate data={data} />;
            default:
                return <DefaultPageTemplate data={data} />;
        }
    };

    return <div>{renderTemplate()}</div>;
};

export default PageTemplates;

export async function getServerSideProps(context) {
    let data = await getPageByPath(context);
    const {clearCache = false} = context?.req?.query || false;

    const id = data?.id || 0;
    const slug = data?.slug || '';

    /** if Spanish page - set the related_posts to just be other pages with the slug "esp" rather than any relation by parent or sibling */
    if (isSpanishPage(slug)) {
        let response = {};
        let spanishList = [];
        try {
            const wpFetch = await WPFetch(
                `/wordpress-api/pages?slug=esp&exclude=${id}&_fields=title,link`,
                clearCache,
            );
            response = wpFetch.response;
            spanishList = wpFetch.data;
            spanishList = spanishList.map((x) => {
                return {...x, title: x?.title?.rendered};
            });
        } catch (e) {
            console.warn(e);
        }
        data['related_posts'] = spanishList;
    }

    // set 404 status code
    if (id === 0 || data?.type !== 'page') {
        return {
            notFound: true,
        };
    } else {
        return {
            props: {
                data,
            },
        };
    }
}
